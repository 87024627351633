import { Box } from "@chakra-ui/react"
import { useToast } from "@chakra-ui/toast"
import React, { useEffect, useState } from "react"
import PlusButton from "../../components/buttons/PlusButton"
import CompanyForm from "../../components/forms/CompanyForm"
import AddUpdateModal from "../../components/modals/AddUpdateModal"
import CompanyTable from "../../components/tables/CompanyTable"
import useGeneric from "../../redux/hooks/useGeneric"
import useMasterdata from "../../redux/hooks/useMasterdata"
import { application, city, company, companyType } from "../../utils/constants"
import { handleToast, makeDropdownOptions } from "../../utils/functions"

const AdminCompaniesPage = () => {
	const hook = useGeneric()
	const hookMd = useMasterdata()
	const toast = useToast()

	const [cities, setCities] = useState([])
	const [companyTypes, setCompanyTypes] = useState([])
	const [applications, setApplications] = useState([])
	const [image, setImage] = useState(null)

	const [colorBlack, setColorBlack] = useState("")
	const [colorBg, setColorBg] = useState("")
	const [colorMedium, setColorMedium] = useState("")
	const [colorDark, setColorDark] = useState("")
	const [colorLight, setColorLight] = useState("")
	const [colorRed, setColorRed] = useState("")

	useEffect(() => {
		hook.cleanToast()
		hook.fetch(company)
		hook.fetch(city)
		hookMd.fetch(companyType)
		hookMd.fetch(application)
	}, []) //eslint-disable-line

	useEffect(() => {
		makeDropdownOptions(hook.cities, setCities)
		makeDropdownOptions(hookMd.companyTypes, setCompanyTypes)
		makeDropdownOptions(hookMd.apps, setApplications)
	}, [hook.cities, hookMd.companyTypes, hookMd.apps])

	useEffect(() => {
		if (hook.message) {
			handleToast(toast, hook.message, "toast1")
		}
	}, [hook.message]) // eslint-disable-line

	const onSubmit = (data) => {
		let formData = new FormData()
		formData.append("code", data.code)
		formData.append("name", data.name)

		if (data.city) {
			formData.append("city", data.city.value)
		} else {
			formData.append("city", "")
		}

		if (data.company_type) {
			formData.append("company_type", data.company_type.value)
		} else {
			formData.append("company_type", "")
		}

		if (data.application) {
			formData.append("app", data.application.value)
		} else {
			formData.append("app", "")
		}

		if (image) {
			formData.append("image", image, image.name)
		}

		if (data.auto_approve_comments) {
			formData.append("auto_approve_comments", "True")
		} else {
			formData.append("auto_approve_comments", "False")
		}

		if (data.use_custom_theme) {
			formData.append("use_custom_theme", "True")
			formData.append("master_color_dark", colorDark)
			formData.append("master_color_background", colorBg)
			formData.append("master_color_normal", colorMedium)
			formData.append("master_color_light", colorLight)
			formData.append("master_color_error", colorRed)
			formData.append("master_color_black", colorBlack)
			formData.append("font", data.font.value)
		} else {
			formData.append("use_custom_theme", "False")
		}

		hook.create(company, formData).then((res) => {
			if (res !== undefined && res.status === 200) {
				hook.handleRefresh(company)
			}
		})
	}

	const fontOptions = [
		{ label: "raleway RALEWAY", value: "Raleway" },
		{ label: "open sans OPEN SANS", value: "open sans" },
		{ label: "nunito NUNITO", value: "nunito" },
		{ label: "mulish MULISH", value: "mulish" },
		{ label: "josefin sans JOSEFIN SANS", value: "josefin sans" },
		{ label: "oswald OSWALD", value: "oswald" },
		{ label: "roboto slab ROBOTO SLAB", value: "roboto slab" },
		{ label: "rubik RUBIK", value: "rubik" },
		{ label: "montserrat MONTSERRAT", value: "montserrat" },
		{ label: "inter INTER", value: "inter" },
	]

	const selectData = {
		cities: cities,
		companyTypes: companyTypes,
		applications: applications,
		fonts: fontOptions,
	}

	const handleImage = (file) => {
		setImage(file)
	}

	const colorData = {
		colorBlack: colorBlack,
		setColorBlack: setColorBlack,
		colorBg: colorBg,
		setColorBg: setColorBg,
		colorMedium: colorMedium,
		setColorMedium: setColorMedium,
		colorDark: colorDark,
		setColorDark: setColorDark,
		colorLight: colorLight,
		setColorLight: setColorLight,
		colorRed: colorRed,
		setColorRed: setColorRed,
	}

	return (
		<Box
			h="95%"
			overflow="auto"
			border={"4px"}
			boxShadow={"0px 2px 10px 2px rgba(34, 178, 186, 0.25)"}
			borderColor="mc_black"
			borderRadius="4px"
			w="100%"
		>
			<CompanyTable data={hook.companies} selectData={selectData} />
			<PlusButton onClick={() => hook.onOpen()} />
			<AddUpdateModal isOpen={hook.isOpen} onClose={hook.onClose} size="full">
				<CompanyForm
					submit={onSubmit}
					close={hook.onClose}
					selectData={selectData}
					handleImage={handleImage}
					colorData={colorData}
					companyImage={image}
				/>
			</AddUpdateModal>
		</Box>
	)
}

export default AdminCompaniesPage
