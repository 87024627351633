import { Button } from "@chakra-ui/button"
import { FormControl, FormLabel } from "@chakra-ui/form-control"
import { Center, Flex } from "@chakra-ui/layout"
import { Checkbox, Collapse, Heading, Img, Stack, Text, useDisclosure } from "@chakra-ui/react"
import React, { useEffect } from "react"
import { HexColorInput, HexColorPicker } from "react-colorful"
import { Controller, useForm } from "react-hook-form"
import { useOutletContext } from "react-router-dom"
import styled from "styled-components"
import { strings } from "../../utils/localization"
import SelectDropdown from "../SelectDropdown"
import UploadFile from "../UploadFile"
import CustomTextInput from "../inputs/CustomTextInput"

const CompanyForm = ({ updateData, submit, close, selectData, handleImage, colorData, companyImage }) => {
	const { register, handleSubmit, control } = useForm()
	const { isOpen: isOpenThemeConfig, onToggle: onToggleThemeConfig } = useDisclosure()

	const config = useOutletContext()

	const { colorBlack, colorBg, colorMedium, colorDark, colorLight, colorRed } = colorData

	useEffect(() => {
		if (updateData) {
			if (updateData.useCustomTheme) {
				onToggleThemeConfig()
				colorData.setColorBlack(updateData.defaultBlackColor)
				colorData.setColorBg(updateData.defaultBackgroundColor)
				colorData.setColorMedium(updateData.defaultMediumColor)
				colorData.setColorLight(updateData.defaultLightColor)
				colorData.setColorRed(updateData.defaultErrorColor)
				colorData.setColorDark(updateData.defaultDarkColor)
			} else {
				colorData.setColorBlack(config.colors.mc_black)
				colorData.setColorBg(config.colors.mc_bg)
				colorData.setColorDark(config.colors.mc_dark)
				colorData.setColorLight(config.colors.mc_light)
				colorData.setColorMedium(config.colors.mc_medium)
				colorData.setColorRed(config.colors.mc_errorRed)
			}
		} else {
			colorData.setColorBlack(config.colors.mc_black)
			colorData.setColorBg(config.colors.mc_bg)
			colorData.setColorDark(config.colors.mc_dark)
			colorData.setColorLight(config.colors.mc_light)
			colorData.setColorMedium(config.colors.mc_medium)
			colorData.setColorRed(config.colors.mc_errorRed)
		}
	}, [])

	return (
		<form
			onSubmit={handleSubmit(submit)}
			style={{
				// minHeight: "420px",
				minHeight: "90vh",
				display: "flex",
				flexDirection: "column",
				justifyContent: "space-between",
			}}
		>
			<Stack spacing={8} mb="30px">
				<Flex gridGap="20px" direction={["column", "row"]}>
					<FormControl>
						<FormLabel color="mc_medium" fontWeight="bold">
							{strings.code}
						</FormLabel>
						<Controller as={<CustomTextInput />} control={control} name="code" defaultValue={updateData && updateData.code} />
					</FormControl>
					<FormControl>
						<FormLabel color="mc_medium" fontWeight="bold">
							{strings.name}
						</FormLabel>
						<Controller as={<CustomTextInput />} control={control} name="name" defaultValue={updateData && updateData.name} />
					</FormControl>
				</Flex>
				<Flex gridGap="20px" direction={["column", "column", "row"]}>
					<FormControl>
						<FormLabel color="mc_medium" fontWeight="bold">
							{strings.city}
						</FormLabel>
						<Controller
							as={<SelectDropdown />}
							control={control}
							name="city"
							options={selectData.cities}
							defaultValue={updateData && updateData.defaultCity}
						/>
					</FormControl>
					<FormControl>
						<FormLabel color="mc_medium" fontWeight="bold">
							{strings.companyType}
						</FormLabel>
						<Controller
							as={<SelectDropdown />}
							control={control}
							name="company_type"
							options={selectData.companyTypes}
							defaultValue={updateData && updateData.defaultCompanyType}
						/>
					</FormControl>
					<FormControl>
						<FormLabel color="mc_medium" fontWeight="bold">
							{strings.application}
						</FormLabel>
						<Controller
							as={<SelectDropdown />}
							control={control}
							name="application"
							options={selectData.applications}
							defaultValue={updateData && updateData.defaultApp}
						/>
					</FormControl>
				</Flex>
				<Checkbox
					mt="60px"
					size="md"
					textTransform="uppercase"
					ref={register}
					name="auto_approve_comments"
					defaultChecked={updateData && updateData.defaultAutoApproveComments}
				>
					{strings.autoApproveComments}
				</Checkbox>
				<Checkbox
					mt="60px"
					size="md"
					textTransform="uppercase"
					onChange={onToggleThemeConfig}
					ref={register}
					name="use_custom_theme"
					defaultChecked={updateData && updateData.useCustomTheme}
				>
					{strings.useCustomTheme}
				</Checkbox>
			</Stack>

			<Collapse in={isOpenThemeConfig}>
				<Stack py="40px" spacing={16} align="center">
					<ColorPickerWrapper>
						<Heading as="h3" fontSize="18px" color="mc_medium" textTransform="uppercase" mb="20px">
							{strings.chooseTheColorOfTheApplication}
						</Heading>
						<Flex
							gridGap={["20px", "20px", "20px", "40px"]}
							wrap="wrap"
							mr="auto"
							textTransform="uppercase"
							fontWeight="bold"
							justify={["center", "center", "unset", "center"]}
						>
							<Stack align="center" justify="center" w="160px">
								<Center bg={colorBlack} py="2px" px="20px" rounded="xl" border="1px solid" borderColor="gray.300">
									<Text color="#fff">{strings.black}</Text>
								</Center>
								<HexColorPicker color={colorBlack} onChange={colorData.setColorBlack} />
								<Flex w="100%" justify="center">
									<HexColorInput color={colorBlack} onChange={colorData.setColorBlack} />
								</Flex>
							</Stack>
							<Stack align="center" w="160px">
								<Center bg={colorBg} py="2px" px="20px" rounded="xl" border="1px solid" borderColor="gray.300">
									<Text>{strings.background}</Text>
								</Center>
								<HexColorPicker color={colorBg} onChange={colorData.setColorBg} />
								<Flex w="100%" justify="center">
									<HexColorInput color={colorBg} onChange={colorData.setColorBg} />
								</Flex>
							</Stack>
							<Stack align="center" w="160px">
								<Center bg={colorDark} py="2px" px="20px" rounded="xl" border="1px solid" borderColor="gray.300">
									<Text color="#fff">{strings.dark}</Text>
								</Center>
								<HexColorPicker color={colorDark} onChange={colorData.setColorDark} />
								<Flex w="100%" justify="center">
									<HexColorInput color={colorDark} onChange={colorData.setColorDark} />
								</Flex>
							</Stack>

							<Stack align="center" w="160px">
								<Center bg={colorMedium} py="2px" px="20px" rounded="xl" border="1px solid" borderColor="gray.300">
									<Text color="#fff">{strings.medium}</Text>
								</Center>
								<HexColorPicker color={colorMedium} onChange={colorData.setColorMedium} />
								<Flex w="100%" justify="center">
									<HexColorInput color={colorMedium} onChange={colorData.setColorMedium} />
								</Flex>
							</Stack>
							<Stack align="center" w="160px">
								<Center bg={colorLight} py="2px" px="20px" rounded="xl" border="1px solid" borderColor="gray.300">
									<Text>{strings.light}</Text>
								</Center>
								<HexColorPicker color={colorLight} onChange={colorData.setColorLight} />
								<Flex w="100%" justify="center">
									<HexColorInput color={colorLight} onChange={colorData.setColorLight} />
								</Flex>
							</Stack>
							<Stack align="center" w="160px">
								<Center bg={colorRed} py="2px" px="20px" rounded="xl" border="1px solid" borderColor="gray.300">
									<Text color="#fff">{strings.red}</Text>
								</Center>
								<HexColorPicker color={colorRed} onChange={colorData.setColorRed} />
								<Flex w="100%" justify="center">
									<HexColorInput color={colorRed} onChange={colorData.setColorRed} />
								</Flex>
							</Stack>
						</Flex>
					</ColorPickerWrapper>
					<Flex
						w="100%"
						maxW="1160px"
						gridGap="20px"
						justify="space-between"
						align="center"
						direction={["column", "column", "row"]}
					>
						<Stack
							w={["100%", "80%", "50%"]}
							h="400px"
							boxShadow="0px 2px 10px rgba(36, 180, 188, 0.25)"
							align="center"
							p="20px"
						>
							<Heading as="h3" fontSize="18px" color="mc_medium" textTransform="uppercase" textAlign="center">
								{strings.selectTheApplicationLogo}
							</Heading>
							<Text color="mc_medium" textTransform="uppercase">
								{strings.currentLogo}:
							</Text>
							<Flex w="100%" h="100%" p={["10px", "30px", "40px"]} justify="center" align="center">
								<Img
									objectFit="contain"
									maxH="180px"
									src={
										companyImage
											? companyImage.preview
											: updateData
											? `${config.baseImageUrl}${updateData.image_name}`
											: ""
									}
								/>
							</Flex>
							<UploadFile onChange={handleImage} />
						</Stack>
						<Stack
							w={["100%", "80%", "50%"]}
							h="400px"
							boxShadow="0px 2px 10px rgba(36, 180, 188, 0.25)"
							align="center"
							p="20px"
						>
							<Heading as="h3" fontSize="18px" color="mc_medium" textTransform="uppercase" textAlign="center">
								{strings.selectTheApplicationFont}
							</Heading>
							<Text color="mc_medium" textTransform="uppercase">
								{strings.font}:
							</Text>
							<SelectWrapper>
								<FormControl>
									<Controller
										as={<SelectDropdown />}
										control={control}
										name="font"
										className="react-select-container"
										classNamePrefix="react-select"
										options={selectData.fonts}
										defaultValue={updateData && updateData.defaultFont}
									/>
								</FormControl>
							</SelectWrapper>
						</Stack>
					</Flex>
				</Stack>
			</Collapse>

			{/* <Center mt={8}>
                {updateData && updateData.image && (
                    <SearchIcon
                        fill="mc_medium"
                        w="22px"
                        h="22px"
                        cursor="pointer"
                        onClick={() => handleSearch(updateData.image_name)}
                    />
                )}
            </Center> */}
			<Flex justify="flex-end" justifySelf="flex-end" mt={4} h="100%">
				<Button onClick={close} variant="red">
					{strings.close}
				</Button>
				<Button type="submit" variant="medium" ml={3}>
					{strings.save}
				</Button>
			</Flex>
			{/* <ImageModal data={img} isOpen={isOpen} onClose={onClose} size="4xl" /> */}
		</form>
	)
}

const ColorPickerWrapper = styled.div`
	.react-colorful {
		width: 160px;
		height: 160px;
	}
	input {
		display: block;
		box-shadow: 0px 2px 10px rgb(36 180 188 / 25%);
		width: 98px;
		padding: 3px;
		border: 1px solid #ddd;
		border-radius: 4px;
		background: inherit;
		outline: none;
		text-transform: uppercase;
		text-align: center;
	}
	input:focus {
		border-color: #4299e1;
	}
`

const SelectWrapper = styled.div`
	width: 100%;
	.react-select-container {
		font-family: "nunito";
		.react-select__option {
			font-size: 18px;
			:first-child {
				font-family: "Raleway";
			}
			:nth-child(2) {
				font-family: "open sans";
			}
			:nth-child(3) {
				font-family: "nunito";
			}
			:nth-child(4) {
				font-family: "mulish";
			}
			:nth-child(5) {
				font-family: "josefin sans";
			}
			:nth-child(6) {
				font-family: "oswald";
			}
			:nth-child(7) {
				font-family: "roboto slab";
			}
			:nth-child(8) {
				font-family: "rubik";
			}
			:nth-child(9) {
				font-family: "montserrat";
			}
			:nth-child(10) {
				font-family: "inter";
			}
		}
	}
`

export default CompanyForm
