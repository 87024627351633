import { useState } from "react"
import BootstrapTable from "react-bootstrap-table-next"
import useGeneric from "../../redux/hooks/useGeneric"
import useUser from "../../redux/hooks/useUser"
import { company } from "../../utils/constants"
import { strings } from "../../utils/localization"
import UpdateButton from "../buttons/UpdateButton"
import CompanyForm from "../forms/CompanyForm"
import AddUpdateModal from "../modals/AddUpdateModal"
import DeleteModal from "../modals/DeleteModal"
import { TablesStyles } from "./TablesStyles"

const CompanyTable = ({ data, selectData }) => {
	const hook = useGeneric()
	const hookUser = useUser()

	const [updateData, setUpdateData] = useState(null)
	const [image, setImage] = useState(null)
	const [colorBlack, setColorBlack] = useState("")
	const [colorBg, setColorBg] = useState("")
	const [colorMedium, setColorMedium] = useState("")
	const [colorDark, setColorDark] = useState("")
	const [colorLight, setColorLight] = useState("")
	const [colorRed, setColorRed] = useState("")

	const columns = [
		{
			dataField: "code",
			text: strings.code,
		},
		{
			dataField: "name",
			text: strings.name,
		},
		{
			dataField: "city_name",
			text: strings.city,
		},
		{
			dataField: "company_type_name",
			text: strings.companyType,
		},
	]

	if (hookUser.admin) {
		columns.push({
			dataField: "app_name",
			text: strings.application,
		})
	}

	const handleUpdateModal = (row) => {
		const defaultCity = selectData.cities.find((e) => e.value === row.city)
		const defaultCompanyType = selectData.companyTypes.find((e) => e.value === row.company_type)
		const defaultApp = selectData.applications.find((e) => e.value === row.app)

		const defaultValues = {
			id: row.id,
			code: row.code,
			name: row.name,
			defaultCity: defaultCity,
			defaultCompanyType: defaultCompanyType,
			image: row.image,
			image_name: row.image_name,
			defaultApp: defaultApp,
			defaultDarkColor: row.master_color_dark,
			defaultBackgroundColor: row.master_color_background,
			defaultMediumColor: row.master_color_normal,
			defaultLightColor: row.master_color_light,
			defaultErrorColor: row.master_color_error,
			defaultBlackColor: row.master_color_black,
			defaultFont: selectData.fonts.find((font) => font.value === row.font),
			useCustomTheme: row.use_custom_theme,
			defaultAutoApproveComments: row.auto_approve_comments,
		}
		setUpdateData(defaultValues)
		hook.onOpen()
	}

	const expandRow = {
		renderer: (row) => (
			<div className="btns-container">
				<DeleteModal remove={() => hook.remove(company, row.id)} />
				<UpdateButton onClick={() => handleUpdateModal(row)} />
			</div>
		),
		className: "expandedRow",
		parentClassName: "parentExpandedRow",
	}

	const handleSubmit = (data) => {
		let formData = new FormData()
		formData.append("id", updateData.id)
		formData.append("code", data.code)
		formData.append("name", data.name)
		if (data.city) {
			formData.append("city", data.city.value)
		} else {
			formData.append("city", "")
		}

		if (data.company_type) {
			formData.append("company_type", data.company_type.value)
		} else {
			formData.append("company_type", "")
		}

		if (data.application) {
			formData.append("app", data.application.value)
		} else {
			formData.append("app", "")
		}
		if (image) {
			formData.append("image", image, image.name)
		}

		if (data.auto_approve_comments) {
			formData.append("auto_approve_comments", "True")
		} else {
			formData.append("auto_approve_comments", "False")
		}

		if (data.use_custom_theme) {
			formData.append("use_custom_theme", "True")
			formData.append("master_color_dark", colorDark)
			formData.append("master_color_background", colorBg)
			formData.append("master_color_normal", colorMedium)
			formData.append("master_color_light", colorLight)
			formData.append("master_color_error", colorRed)
			formData.append("master_color_black", colorBlack)
			formData.append("font", data.font.value)
		} else {
			formData.append("use_custom_theme", "False")
		}

		hook.update(company, formData).then((res) => {
			if (res !== undefined && res.status === 200) {
				hook.handleRefresh(company)
			}
		})
	}

	const handleImage = (file) => {
		setImage(file)
	}

	const colorData = {
		colorBlack: colorBlack,
		setColorBlack: setColorBlack,
		colorBg: colorBg,
		setColorBg: setColorBg,
		colorMedium: colorMedium,
		setColorMedium: setColorMedium,
		colorDark: colorDark,
		setColorDark: setColorDark,
		colorLight: colorLight,
		setColorLight: setColorLight,
		colorRed: colorRed,
		setColorRed: setColorRed,
	}

	return (
		<>
			<TablesStyles>
				<BootstrapTable
					data={data}
					columns={columns}
					keyField="id"
					classes="TablesStyles"
					expandRow={expandRow}
					bordered
					bootstrap4
				/>
			</TablesStyles>

			<AddUpdateModal isOpen={hook.isOpen} onClose={hook.onClose} size="full">
				<CompanyForm
					updateData={updateData}
					close={hook.onClose}
					submit={handleSubmit}
					selectData={selectData}
					handleImage={handleImage}
					colorData={colorData}
					companyImage={image}
				/>
			</AddUpdateModal>
		</>
	)
}

export default CompanyTable
