export const macedonian = () => {
	return {
		showMore: "Прикажи повеќе",
		edit: "уредување",
		educationAuthorIs: "Оваа едукација е направена од компанијата:",
		startTest: "започнете тест",
		didTest: "Направивте тест",
		maxTimes: "максимален број пати",
		endTest: "ПОПОЛНЕТЕ ГО ТЕСТОТ",
		requiredFieldsMessage: "Полињата означени со * се задолжителни!",
		allRequiredFieldsMessage: "Сите полиња се задолжителни!",
		requiredTextOrImageMessage: "Немате внесено текст или слика!",
		requiredTextMessage: "Немате внесено никаков текст!",
		requiredTextOfAllAnswersMessage: "Пополнете го текстот на сите одговори!",
		oneCorrectAnswerMessage: "Само еден одговор може да биде точен!",
		atLeastOneCorrectAnswerMessage: "Најмалку еден одговор мора да биде точен!",
		requiredImageMessage: "Сликата мора да биде избрана!",
		requiredQuestionTextMessage: "Внесете го текстот на прашањето!",
		requiredEducationSlideNumberMessage: "Внесете го бројот на образовниот слајд!",
		requiredAllQuestions: "Пополнете ги сите прашања!",
		passwordDontMatchMessage: "Лозинките не се совпаѓаат!",
		noDataSelectedMessage: "Податоците не се избрани!",
		noReportSelectedMessage: "Извештајот не е избран!",
		noTestSelectedMessage: "Тестот не е избран!",
		noTypeSelectedMessage: "Типот не е избран!",
		noArticleSelected: "Вестите не се избрани!",
		noSurveySelected: "Анкетата не е избрана!",
		noJobSelected: "Работата не е избрана!",
		notEnoughReportDataMessage: "Нема доволно податоци за избраниот извештај",
		enterCommentMessage: "Внесете коментар!",
		leaveComment: "ОСТАВИ КОМЕНТАР",
		report: "ИЗВЕШТАЈ",
		test: "ТЕСТ",
		type: "ТИП",
		from: "ОД",
		to: "ДО",
		previous: "Претходна",
		next: "Следна",
		page: "Страна",
		of: "од",
		generate: "ГЕНЕРИРАЈ",
		choosePlaceholder: "Изберете...",
		name: "ИМЕ",
		title: "НАСЛОВ",
		education: "Образование",
		category: "КАТЕГОРИЈА",
		application: "ПРИМЕНА",
		company: "КОМПАНИЈА",
		survey: "АНКЕТА",
		country: "ЗЕМЈА",
		city: "ГРАД",
		jobTitle: "НАСЛОВ",
		position: "ПОЗИЦИЈА",
		language: "ЈАЗИК",
		phoneNumber: "ТЕЛЕФОНСКИ БРОЈ",
		licenceNumber: "БРОЈ НА ЛИЦЕНЦА",
		biography: "БИОГРАФИЈА",
		slideText: "СЛАЈД ТЕКСТ",
		image: "СЛИКА",
		question: "ПРАШАЊЕ",
		questions: "ПРАШАЊА",
		educationSlide: "ОБРАЗОВЕН ЛИЗДАВ",
		answers: "ОДГОВОРИ",
		summaryText: "РЕЗИМЕ",
		coverPhoto: "НАСЛОВНА ФОТОГРАФИЈА",
		content: "СОДРЖИНА",
		status: "СТАТУС",
		close: "ЗАТВОРИ",
		back: "НАЗАД",
		forward: "НАПРЕД",
		save: "ЗАЧУВАЈ",
		password: "ЛОЗИНКА",
		repeatPassword: "ПОВТОРИ ЈА ЛОЗИНКАТА",
		newPassword: "НОВА ЛОЗИНКА",
		newPasswordRepeat: "ПОВТОРИ НОВА ЛОЗИНКА",
		code: "КОД",
		companyType: "ВИД НА ФИРМА",
		firstName: "ИМЕ",
		lastName: "ПРЕЗИМЕ",
		username: "КОРИСНИЧКО ИМЕ",
		email: "И-МЕЈЛ",
		supervisor: "СУПЕРВИЗОР",
		users: "КОРИСНИЦИ",
		reports: "ИЗВЕШТАИ",
		importExport: "УВОЗ/ИЗВОЗ",
		masterData: "МАСТЕР ПОДАТОЦИ",
		countries: "ЗЕМЈИ",
		jobTitles: "НАСЛОВИ",
		positions: "ПОЗИЦИИ",
		companyTypes: "ВИДОВИ НА ФИРМИ",
		cities: "ГРАДОВИ",
		companies: "КОМПАНИИ",
		educationCategories: "ОБРАЗОВАНИ КАТЕГОРИИ",
		articleCategories: "КАТЕГОРИИ НА ВЕСТИ",
		languages: "ЈАЗИЦИ",
		article: "ВЕСТ",
		articles: "ВЕСТИ",
		surveys: "АНКЕТИ",
		educations: "ОБРАЗОВАНИЕ",
		tests: "ТЕСТОВИ",
		panel: "ПАНЕЛ",
		signOut: "ОДЈАВИ СЕ",
		signIn: "НАЈАВИ СЕ",
		controlPanel: "КОНТРОЛЕН ПАНЕЛ",
		profile: "ПРОФИЛ",
		cancel: "ОТКАЖИ",
		delete: "ИЗБРИШИ",
		deleteQuestion: "ИЗБРИШИ ПРАШАЊЕ",
		deleteWarningMessage: "Ако ја избришете оваа ставка, нема да можете да ја вратите!",
		yes: "ДА",
		no: "НЕ",
		creationDate: "ДАТУМ НА ПРОИЗВОДСТВО",
		views: "ПОГЛЕДИ",
		reactions: "РЕАКЦИИ",
		comments: "КОМЕНТАРИ",
		registrationTime: "ВРЕМЕ НА РЕГИСТРАЦИЈА",
		lastLogin: "ПОСЛЕДНА НАЈАВА",
		active: "АКТИВНИ",
		congratulations: "ЧЕСТИТКИ",
		completedTest: "ВИЕ УСПЕШНО ГО ПОВРШИВТЕ ТЕСТОТ",
		monthlyReport: "Месечен извештај",
		fileSelected: "Избрана е датотека",
		chooseCsvFile: "Изберете .csv датотека",
		photo: "Фотографија",
		selectPhoto: "Изберете фотографија",
		noFileSelectedMessage: "Датотеката не е избрана!",
		exportData: "ИЗВОЗНИ ПОДАТОЦИ",
		export: "ИЗВОЗ",
		importData: "УВЕЗНИ ПОДАТОЦИ",
		import: "УВОЗ",
		confirm: "ПОТВРДИ",
		noComments: "Нема коментари",
		loadMore: "ТОВАРИ ПОВЕЌЕ",
		author: "АВТОР",
		notFoundMessage: "Страницата што ја барате не е пронајдена",
		updateData: "АЖУРИРАЈ ПОДАТОЦИТЕ",
		privacyPolicy: "ПОЛИТИКА ЗА ПРИВАТНОСТ",
		result: "РЕЗУЛТАТ",
		serverErrorMessage: "Грешка во серверот!",
		wrongCredentialsMessage: "Не беше пронајдена сметка со дадени акредитиви!",
		noAccount: "Нема сметка?",
		clickHere: "Кликни тука",
		forgotPassword: "Ја заборави лозинката?",
		backTo: "Назад на",
		loginPage: "страница за најавување",
		emailAddress: "И-МЕЈЛ АДРЕСА",
		passwordReset: "РЕСЕТИРАЊЕ НА ЛОЗИНКАТА",
		registration: "ПРИЈАВЕТЕ СЕ",
		noMore: "Нема повеќе!",
		welcomeTo: "Добредојдовте во",
		byUser: "ОД КОРИСНИК",
		byCity: "ПО ГРАД",
		byQuestions: "ПО ПРАШАЊА",
		loginCount: "Број на најавите",
		articleViews: "Прегледи на вести",
		articleReactions: "Реакциите на вестите",
		educationViews: "Ставови за образование",
		passedTests: "Положени тестови",
		monthly: "МЕСЕЧЕН",
		eduAll: "СИТЕ",
		eduChoose: "ИЗБЕРЕТЕ",
		eduChooseCompleted: "ИЗБЕРЕТЕ ЗАВРШЕНИ",
		eduChooseNotCompleted: "ИЗБЕРЕТЕ НЕ ЗАВРШЕНО",
		noEduSelectedMessage: "Образованието не е избрано!",
		deleteSlide: "ИЗБРИШИ СЛАЈД",
		cantDeleteFirst: "Не можете да го избришете првиот слајд!",
		agreePrivacyPolicy: "Се согласувам со Политиката за приватност",
		articleAll: "СИТЕ",
		articleByUserReports: "СО ИЗВЕШТАИ НА КОРИСНИК",
		imageMissing: "Некаде недостасува слика!",
		monthlyPoints: "ПОЕНИ ЗА ОВОЈ МЕСЕЦ:",
		annualPoints: "ПОЕНИ ЗА ОВАА ГОДИНА:",
		notePlaceholder: "Забелешка...",
		wrongAppMessage: "Се обидувате да пристапите на погрешна платформа!",
		selectFile: "Изберете датотека",
		selectDocument: "Изберете документ",
		searchPlaceholder: "Барај...",
		repeatTest: "ПОВТОРИ ГО ТЕСТОТ",
		usersUpdate: "АЖУРИРАЊЕ НА КОРИСНИЦИТЕ",
		total: "ВКУПНО",
		track: "ПЕСТА",
		loginData: "ПОДАТОЦИ ЗА ВЛЕЗ",
		checkEmailMessage: "Отворете ја врската за е-пошта за да ја потврдите регистрацијата (проверете спам)",
		subquestion: "ПОТПРАШАЊЕ",
		comment: "КОМЕНТАР",
		reject: "ОТФРЛИ",
		approve: "ОДОБРИ",
		tags: "ТАГОВИ",
		jobListings: "РАБОТА РАБОТА",
		deadline: "РОК",
		applicationDeadline: "Краен рок за аплицирање",
		apply: "се применуваат",
		openFormToApply: "отворена форма за аплицирање",
		uploadYourCV: "прикачете ВАШЕ CV",
		uploadFile: "Поставете датотека",
		yourCVIsUploaded: "вашето CV е поставено",
		applied: "ПРИМЕНЕТО",
		jobs: "РАБОТА",
		job: "РАБОТА",
		note: "Забелешка",
		newEmployees: "Нови вработени",
		newEmployee: "НОВ ВРАБОТЕН",
		updateEmploymentStatus: "Ажурирајте го статусот на вработување",
		banner: "банер",
		url: "url",
		expirationDate: "ДАТА НА ИСТЕКУВАЊЕ",
		expired: "ИСТЕРЕН",
		days: "ДЕНОВИ",
		hours: "ЧАСОВИ",
		useCustomTheme: "користете прилагодена тема",
		chooseTheColorOfTheApplication: "изберете ја бојата на апликацијата",
		selectTheApplicationLogo: "изберете го логото на апликацијата",
		selectTheApplicationFont: "изберете го фонтот на апликацијата",
		currentLogo: "сегашното лого",
		font: "фонт",
		black: "црна",
		background: "позадина",
		dark: "темно",
		medium: "средно",
		light: "светлина",
		red: "црвено",
		assignedToUsers: "ДОДЕЛЕНИ НА КОРИСНИЦИ",
		testAnswerQuestion: "Одговори на прашањето",
		testMaxRepeatNumberReached: "Сте го направиле тестот максимален број пати",
		UA_LOGIN_ACTIVITY: "Активност",
		UA_NEWS_VIEWS: "Вести",
		UA_NEWS_REACTIONS: "Реакции",
		UA_EDUCATION_VIEWS: "Образованија",
		UA_TESTS: "Тестови",
		UA_LOGIN_ACTIVITY_TP: "Корисничка активност во претходните 100 дена",
		UA_NEWS_VIEWS_TP: "Вести видени во претходните 100 дена",
		UA_NEWS_REACTIONS_TP: "Број на реакции во претходните 100 дена",
		UA_EDUCATION_VIEWS_TP: "Успешно завршени едукации во претходните 100 дена",
		UA_TESTS_TP: "Активност на положени тестови во претходните 100 дена",
		ACTIVITY: "Активност",
		NEWS: "Вести",
		EDUCATIONS: "Образованија",
		TESTS: "Тестови",
		achievements: "Достигнувања",
		ACTIVITY_DAYS: "денови на последователно најавување",
		EDUCATIONS_FINISHED: "завршено образование",
		NEWS_LIKED: "допадна вест",
		NEWS_VIEWED: "прегледани вести",
		TESTS_SUCCESS_FINISHED: "положени тестови со сите точни одговори",
		TESTS_FINISHED: "завршени тестови",
		numberOfAchievements: "Број на достигнувања",
		currentRank: "Тековен ранг",
		numberOfUnread: "Број на непрочитани",
		numberOfNotCompleted: "Број на незавршени",
		documents: "Документи",
		document: "Документ",
		download: "преземете",
		completedAllEdu: "Ги завршивте сите",
		readAllNews: "Сте ги прочитале сите",
		forms: "Форми",
		month: "Месец",
		year: "Година",
		absenceRequests: "БАРАЊА ЗА ОТСУСТВО",
		absenceRequestType: "ТИП НА ОТСУСТВО",
		byAssignedUser: "ОД НАДЕЛЕН КОРИСНИК",
		bySubQuestions: "СО ПОТПРАШАЊА",
		lockedEduMessage:
			"Почитуван корисник, се додека сте нововработен, мора да правите обуки за нововработените, за да имате пристап до други обуки.",
		mpoorj: "MPO/ORJ",
		cv: "CV",
		FORM_ZZAZ: "Барање за административна забрана",
		FORM_PZDF: "Апликација за споделување флаери",
		contactPhoneNumber: "КОНТАКТ ТЕЛЕФОНСКИ БРОЈ",
		FORM_TK: "Тркачки клуб",
		FORM_ZPOZ: "Барање за сертификати за вработување",
		bank: "БАНКА",
		certificationPurpose: "ЦЕЛ НА СЕРТИФИКАЦИЈА",
		documentType: "ВИД НА ДОКУМЕНТ",
		documentVerification: "ДОКУМЕНТ ЗА ВЕРИФИКАЦИЈА",
		loanStatement: "ИЗЈАВА ЗА НЕЗАВИСНА ОТПЛАТА НА ЗАЕМОТ",
		FORM_ZZO: "Остави барања",
		leaveType: "ВИД НА ОТСУСТВО",
		dateFrom: "ДАТА ОД",
		dateTo: "ДАТУМ НА",
		absenceDocument: "ДОКУМЕНТ ЗА ОТСУСТВО",
		FORM_PK: "Победничка комбинација",
		commentNote: "КОМЕНТАР/БЕЛЕШКА",
		recommendation: "ПРЕПОРАКА",
		FORM_IZ: "Иницијативи за вработени",
		initiative: "ИНИЦИЈАТИВА",
		choose: "Изберете",
		initiativeTitle: "НАСЛОВ НА ИНИЦИЈАТИВАТА",
		autoApproveComments: "АВТОМАТСКИ ОДОБРУВАЈТЕ КОМЕНТАРИΑ",
	}
}
